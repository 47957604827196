import lottie from "lottie-web";
import 'jquery';

let renderDiv = document.getElementById("render-div");
let progressBarHolder = document.getElementById("progressbar-holder");
let progressBar = document.getElementById("progress-bar");
let progressBarDiv = document.getElementById("progressbar-status");
let loaderBallAnimation = document.getElementById("loader-ball-animation");
let startButton = document.getElementById("start-btn");
let soundButton = document.getElementById("sound-btn");
let soundOnIcon = document.getElementById("sound-on");
let soundOffIcon = document.getElementById("sound-off");

let totalFrames = 10; //value is updated from lottie json
let limit = document.documentElement.scrollHeight - document.documentElement.clientHeight;
let lastKnownScrollPosition = 0;
let ticking = false;
let scrollMargin = 500;

window.scrollTo(0, scrollMargin);
document.body.style.overflow = 'hidden';

function showFrame(scrollPos) {
    //console.log(scrollPos, limit)
    if (scrollPos > limit - scrollMargin) {
        scrollPos = scrollMargin + scrollPos - (limit - scrollMargin);
        window.scrollTo(0, scrollPos);
    }
    else if (scrollPos < scrollMargin) {
        scrollPos = limit - scrollMargin - (scrollMargin - scrollPos);
        window.scrollTo(0, scrollPos);
    }

    //console.log("pos: ", limit, document.body.scrollTop, window.scrollY, window.pageYOffset)
    let animationFrameIndex = Math.floor(((scrollPos - scrollMargin) / (limit - scrollMargin * 2)) * (totalFrames - 1));
    // showFrame: 931 scrollpos: 12898 limit top: 500 limit bottom: 12898
    //let pos = Math.floor(scrollPos / limit * totalFrames)
    console.log("showFrame: " + animationFrameIndex, "scrollpos: " + scrollPos, "limit top: " + scrollMargin, "limit bottom: " + (limit - scrollMargin));
    anim.goToAndStop(animationFrameIndex, true);
}

document.addEventListener('scroll', (e) => {
    lastKnownScrollPosition = window.scrollY;

    if (!ticking) {
        window.requestAnimationFrame(() => {
            showFrame(lastKnownScrollPosition);
            ticking = false;
        });

        ticking = true;
    }
});

let lottieFile = window.screen.width > 1080 || window.screen.height > 1080 ? 'lottie/data.json' : 'lottie_sd/data.json';
console.log(lottieFile);

let loaderAni = lottie.loadAnimation({
    container: loaderBallAnimation, // the dom element that will contain the animation
    renderer: 'svg',
    //renderer: 'svg',
    loop: true,
    autoplay: true,
    path: "loading-animation.json", // the path to the animation json
    rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice',
        /*preserveAspectRatio: 'ignore',*/
        /*imagePreserveAspectRatio: 'xMidYMid slice'*/
    }
});


let anim = lottie.loadAnimation({
    container: renderDiv, // the dom element that will contain the animation
    renderer: 'canvas',
    //renderer: 'svg',
    loop: true,
    autoplay: false,
    path: lottieFile, // the path to the animation json
    rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice',
        /*preserveAspectRatio: 'ignore',*/
        /*imagePreserveAspectRatio: 'xMidYMid slice'*/
    }
});



//start time out for the intro screen
/*
let introTimeOutReady = false;
let introTimeoutID = setTimeout(function () {
    introTimeOutReady = true;
    clearTimeout(introTimeoutID);
    if (imagesReady)
        startAnimation();
}, minIntroTime * 1000)
*/


function startAnimation() {
    $(progressBarHolder).fadeOut("slow");
    $("#scroll-hint-div").delay(3000).fadeOut("slow");
    document.body.style.overflow = 'visible';
    window.scrollTo(0, scrollMargin);
    anim.goToAndStop(1, true);
}

// event handler for animation images loaded
let imagesReady = false;
anim.addEventListener('loaded_images', () => {
    imagesReady = true;
    progressBarDiv.style.width = '100%';
    clearInterval(imageLoaderTimerId);
    $(progressBar).animate({ opacity: 0 }, 500)
    $(loaderBallAnimation).animate({ opacity: 0 }, 500)
    loaderAni.pause();
    //progressBar.style.visibility = 'hidden';

    $(startButton).css('visibility', 'visible').fadeIn()

    console.log("loaded_images");
    console.log("anim.imagePreloader.loadedAssets", anim.imagePreloader.loadedAssets, anim.totalFrames)
});

// event handler for initial lottie json loaded
let imageLoaderTimerId
anim.addEventListener('data_ready', () => {
    console.log("data_ready");
    totalFrames = anim.totalFrames;

    console.log(anim.imagePreloader.totalImages)
    if (anim.imagePreloader.totalImages > 0) {
        imageLoaderTimerId = setInterval(() => {
            progressBarDiv.style.width = Math.max((anim.imagePreloader.loadedAssets / anim.imagePreloader.totalImages * 100), 10).toString() + "%";
        }, 500);
    }
    else {
        imagesReady = true;
        progressBarDiv.style.width = '100%';

        $(startButton).css('visibility', 'visible').fadeIn()
    }
});


startButton.addEventListener("click", function () {
    var audio = document.getElementById("audio");
    audio.play();
    startAnimation();
});

let soundIsPlaying = true;
soundButton.addEventListener("click", function () {
    var audio = document.getElementById("audio");
    if (soundIsPlaying) {
        $(soundOnIcon).css('display', 'None').fadeIn();
        $(soundOffIcon).css('display', 'None');
        audio.pause();
    } else {
        $(soundOffIcon).css('display', 'None').fadeIn();
        $(soundOnIcon).css('display', 'None');
        audio.play();
    }
    soundIsPlaying = !soundIsPlaying;

});


window.addEventListener('resize', () => {
    console.log("resizing ", document.documentElement.clientWidth, window.screen.width, window.screen.height);
    limit = document.documentElement.scrollHeight - document.documentElement.clientHeight;
    anim.resize();
    //TODO recalc scroll
});